<template> 
    <div  style="width:100%;height: calc(var(--vh, 1vh) * 100);overflow-y: scroll;">
        <div id="content">
            <div id="error-section" class="uaxL4e">We're sorry, the requested URL was not found on this server.</div>


            <div id="search-section" class="uaxL4e">
                <a href="/">
                    <img id="play-logo" alt="Google Play" src="/img/login/logo_6.png">
                </a>
                <form id="search-box" method="get" action="#" style="margin: 29px 132px;">
                    <input type="text" value="" name="q">
                    <input type="submit" value="Search"  @click="skip">
                </form>
                <div style="clear:both">

                </div>
            </div>

        </div>


       <!-- <div style="    margin: 0 auto;width: 750px;">
       
                <div style="    background-color: #d2e3fb;
                    border: 1px solid #a1b4d9;
                    color: #666;
                    font-weight: bold;
                    padding: 12px 0;">
                    <span style="font-size: 16px;">
                        We're sorry, the requested URL was not found on this server.
                    </span>
                </div>
    


                <div id="search-section" class="uaxL4e">
                    <div style="display:inline-block;">
                        <a href="/">
                            <img  id="play-logo" src="/img/login/logo_6.png" alt="logo图">
                        </a>
                    </div>

                    <div style="display: flex;margin-left: 20%;font-size: 17px;">
                        <input type="text" value="" name="q" style="width: 60%;height: 30px;" >
                        <input type="submit" value="Search" style="height: 30px;"  @click="skip">
     
            
                
                    </div> 
                </div>


        </div> --> 
       
    </div>    

 </template>
 <style>
    #play-logo {
        float: left;
        margin: 17px;
        width:  6.83092rem;;

    }
    #search-section {
        border: 1px solid #a1b4d9;
        margin: 10px 0;
    }
    #error-section {
        background-color: #d2e3fb;
        border: 1px solid #a1b4d9;
        color: #666;
        font-weight: bold;
        padding: 12px 0;
    }
    #content {
        margin: 0 auto;
        width: 750px;

        font-family: arial, sans-serif;
        margin: 50px 10px;
        padding: 0;
        text-align: center;
        font-size: 15px;
    }
    a:-webkit-any-link {
        color: -webkit-link;
        cursor: pointer;
        text-decoration: underline; 
    }

</style>
 <script>
import { useRouter } from "vue-router";
     export default {
         name: "404",
         setup(){
            const router = useRouter();

            const skip = ()=>{
                router.push({ path: '/', query: {} })
            }

            return {
                skip
            };
         },
         components: {
         
         }
     }
 </script>